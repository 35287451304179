.blank-page{
	background-color: #fff;
}
#main-menu-navigation{
	justify-content: center;
}
.navbar-logo-center img{

}

.custom_product{
	opacity: 0;
	position: fixed;
    top: 1rem;
    left: 5rem;
    z-index: 999;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar.custom-nav {
  min-height: 6.45rem;
}
.horizontal-layout.navbar-floating .horizontal-menu-wrapper .navbar-horizontal.floating-nav {
  margin: 1.3rem auto 0!important;
}
#main-menu-navigation {
  justify-content: right!important;
}
.header-navbar.floating-nav {
  right: 0;
  left: 0;
}
.header-navbar.navbar-fixed.align-items-center.navbar-shadow.navbar-brand-center.navbar.navbar-expand-lg {
  display: none;
}
.horizontal-menu .horizontal-menu-wrapper{
	top:0px!important;
}

.horizontal-layout.navbar-floating:not(.blank-page) .app-content {
  padding: calc(2rem + 3.45rem* 2 + 1.3rem) 2rem 0 2rem!important;
}

.video-react.video-react-fluid, 
.video-react .video-react-video,
.video-react .video-react-poster{
  border-radius: 10px;
}


.recoder-holder .close-btn .x-btn {
  border-radius: 6px!important;
  background-color: #633589!important;
  border: 1px solid #633589!important;
}
.recoder-holder .close-btn .x-btn {
  color: #ffffff;
  width: auto!important;
  height: 2.5rem!important;
  padding: 0px 10px!important;
}



.record-button__RecWrapper-sc-1n5amwk-1.fHBbdT {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: bottom;
    position: absolute;
    bottom: 0rem;
}

/*.cIPVnM {
    position: absolute;
    left: 0px;
    bottom: 2rem!important;
    top: unset!important;
    right: 0px;
}*/

.cIPVnM {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 2rem;
  top: unset!important;
}